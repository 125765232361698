import React, { Component } from "react";
import {
    Layout,
    Row,
    Col,
    Button,
    Divider,
    notification,
    Timeline,
    Icon,
    Tooltip
} from "antd";

import "antd/dist/antd.css";
import "./index.css";

const { Content, Footer } = Layout;

const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button
            type="primary"
            onClick={() => {
                notification.close(key);
            }}
            icon="check-circle"
        >
            Okay verstanden
        </Button>
    );

    notification.open({
        message: "🍪 Hier werden Cookies verwendet",
        description:
            "Ich verwende Cookies auf meiner Website, um Ihren Besuch effizienter zu gestalten und Ihnen mehr Benutzerfreundlichkeit bieten zu können.",
        btn,
        placement: "bottomRight",
        duration: null,
        key,
        onClose: () => {
            localStorage.setItem("cookie-consent-given", true);
        }
    });
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeClass: "non-scrolled",
            trackingAllowed: JSON.parse(
                localStorage.getItem("tracking-opt-out")
            )
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", event => {
            let className = "non-scrolled",
                { scrollY } = window;

            if (scrollY >= 50) {
                className = "scrolled";
            }

            this.setState({
                activeClass: className
            });
        });

        if (!localStorage.getItem("cookie-consent-given")) openNotification();
    }

    render() {
        return (
            <Layout
                style={{ minHeight: "100vh" }}
                className={this.state.activeClass}
            >
                <Content>
                    <Row
                        align="middle"
                        type="flex"
                        justify="center"
                        className="content-wrapper"
                    >
                        <Col xs={18} sm={8} md={6} className="text-center">
                            <div className="box-shadow profile-picture">
                                <img
                                    src="portrait-tb.jpg"
                                    alt="Portraitansicht von Tobias Braune"
                                    className="img-responsive"
                                />
                            </div>

                            {this.state.showDetails && (
                                <React.Fragment>
                                    <Divider orientation="left">
                                        Berufliche Laufbahn
                                    </Divider>

                                    <Timeline mode="alternate">
                                        <Timeline.Item>
                                            Create a services site 2015-09-01
                                        </Timeline.Item>
                                        <Timeline.Item color="green">
                                            Solve initial network problems
                                            2015-09-01
                                        </Timeline.Item>
                                        <Timeline.Item
                                            dot={
                                                <Icon
                                                    type="clock-circle-o"
                                                    style={{ fontSize: "16px" }}
                                                />
                                            }
                                        >
                                            Sed ut perspiciatis unde omnis iste
                                            natus error sit voluptatem
                                            accusantium doloremque laudantium,
                                            totam rem aperiam, eaque ipsa quae
                                            ab illo inventore veritatis et quasi
                                            architecto beatae vitae dicta sunt
                                            explicabo.
                                        </Timeline.Item>
                                        <Timeline.Item color="red">
                                            Network problems being solved
                                            2015-09-01
                                        </Timeline.Item>
                                        <Timeline.Item>
                                            Create a services site 2015-09-01
                                        </Timeline.Item>
                                        <Timeline.Item
                                            dot={
                                                <Icon
                                                    type="clock-circle-o"
                                                    style={{ fontSize: "16px" }}
                                                />
                                            }
                                        >
                                            Technical testing 2015-09-01
                                        </Timeline.Item>
                                    </Timeline>
                                </React.Fragment>
                            )}

                            <p>
                                Über folgende Profile kann man mich auffinden
                                <br />
                                <a href="https://www.xing.com/profile/Tobias_Braune/cv">
                                    XING
                                </a>
                                &nbsp;&middot;&nbsp;
                                <a href="https://www.linkedin.com/in/tobiasbraune/">
                                    LinkedIn
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Content>
                <Footer className="responsive-footer">
                    Made with
                    <Tooltip title="JS React Framework">
                        <a href="https://reactjs.org/">
                            <img src="icons/react.svg" alt="react" />
                        </a>
                    </Tooltip>
                    <Tooltip title="Ant Design Framework">
                        <a href="https://ant.design/">
                            <img src="icons/antd.svg" alt="ant.design" />
                        </a>
                    </Tooltip>
                    <Tooltip title="Hosted at GitLab">
                        <a href="https://gitlab.com/">
                            <img src="icons/gitlab.svg" alt="GitLab" />
                        </a>
                    </Tooltip>
                    
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.setState(
                                {
                                    trackingAllowed: !this.state.trackingAllowed
                                },
                                () => {
                                    localStorage.setItem(
                                        "tracking-opt-out",
                                        this.state.trackingAllowed
                                    );
                                }
                            );
                        }}
                    >
                        {this.state.trackingAllowed
                            ? "Tracking deaktiviert"
                            : "Tracking aktiviert"}
                    </span>
                </Footer>
            </Layout>
        );
    }
}

export default App;
